<template>
  <div>
    <div id="container" class="container" @scroll="scroll">
      <table id="tabla" ref="tabla" v-if="datos" :width="ancho+'px'" :max-width="ancho+'px'" :key="key+1">
        <thead>
          <tr class="titulos" :key="key+1">
              <th v-for="(item,index) in campos" :class="clases(item.fijado)" :style="'left:'+item.fijado+'px'" :key="index"  v-bind:width="(parseInt(item.ancho)+1)+'px'">
                <div class="titulo" v-if="filtrable===true">
                  <label :title="'Ordenar por '+item.titulo" @click="ordenar(item)">{{item.titulo}}</label>
                </div>
                <div v-if="filtrable===true">
                  <Filtro @filtrar="filtrar" :multiple="true" :ref="item.nombre" :campo="item.nombre" :opciones="listas[item.nombre.toLowerCase()]" :tipo="item.tipo" width="100%"/>
                </div>
                <div v-else>
                  <label>{{item.titulo}}</label>
                </div>
              </th>
              <th :width="'50px'">
                  <!-- Esta columna sirve para poder hacer mas grande la última -->
              </th>
          </tr>
        </thead>
        <tr class="registro" v-for="(a,registro) in datos" :key="registro">
          <td v-for="(item,index) in campos" :class="clases(item.fijado)" :style="'left:'+item.fijado+'px'"  :key="index"  :width="(parseInt(item.ancho))+'px'">
            <input v-if="item.tipo==='boolean'" disabled type="checkbox" v-model="a[item.nombre]">
            <span class="link" v-else-if="item.link" @click="link(a,item)">{{a[item.nombre]}}</span>
            <span v-else>{{a[item.nombre]}}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Filtro from '@/components/Filtro.vue'
export default {
    name: 'Tabla',
    components: {
      Filtro
    },
    data: function (){
      return {
        key: 1,
        pato: -1,
        orden: null,
        tipo_orden: null,
        filtros: {},
        anchos: [],
        algo: null
      }
    },
    props: {
        datos: Array,
        campos: Array,
        filtrable: Boolean
    },
    computed: {
        ...mapState(['cache','tablas','listas','preferencias']),
        ancho(){
          let suma = 0
          if(this.campos){
            for (let index = 0; index < this.campos.length; index++) {
              suma += parseInt(this.campos[index].ancho) + 7
            }
            return suma
          }else{
            return 0
          }
        }
    },
    methods: {
        ...mapActions(['api','cargarreferencias','cargartabla']),
        filtrar(e){
          if(e){
            if(e[1]){
              this.filtros[e[0]]=e[1]
            }else{
              delete this.filtros[e[0]]
            }
          }
          this.$emit('filtrar',this.filtros)
          // Aqui emitiremos un evento para que el componente padre...
          // ...(poseedor del componente Tabla) realice la peticion a backend
        },
        quitarfiltros(){
          this.campos.forEach(campo => {
              this.$refs[campo.nombre].cargar()
          })
          this.filtros= {}
          this.filtrar()
        },
        ordenar(e){
          if(this.orden === e.nombre){
            if(this.tipo_orden==='ASC'){
              this.tipo_orden = 'DESC'
            }else if(this.tipo_orden === 'DESC'){
              this.tipo_orden = 'ASC'
            }
          }else{
            this.tipo_orden = this.ordenes(e)
          }
          this.orden = e.nombre
          if(e.tipo === 'option' || e.tipo === 'option-long'){
            this.$emit('ordenar','Z'+this.orden+' '+this.tipo_orden) // emito el evento que capturara el padre para ordenar
          }else{
            this.$emit('ordenar',this.orden+' '+this.tipo_orden) // emito el evento que capturara el padre para ordenar
          }
        },
        ordenes(e){
          if(e.tipo==='string' || e.tipo === 'option' || e.tipo==='option-long' || e.tipo === 'boolean'){
            return 'ASC'
          }else if(e.tipo === 'number' || e.tipo === 'money' || e.tipo === 'date'){
            return 'DESC'
          }
        },
        scroll(){
          this.$emit('scroll')
        },
        link(e,campo){
          this.$emit('link',{id:e.ID,tabla:campo.link.toLowerCase()})
          // Aqui emitiremos un evento para que el componente padre despliegue el detalle de...
        },
        ver_mas(){
          // Aqui emitiremos un evento para que el padre pida a backend mas registros
        },
        clases(valor){
          if(Number.isInteger(valor) && valor >= 0){
            return 'sticky'
          }else{
            return ''
          }
        },
        
    },
    mounted(){
      this.anchos = this.campos.map((e)=>{
        return parseInt(e.ancho)
      })
      let a = this.anchos
      let b = this
      this.algo = window.setInterval(function(){
        let fila = document.getElementById('tabla').getElementsByTagName('tr')[0].getElementsByTagName('th')
          let array = []
          for (let index = 0; index < fila.length; index++) {
            if(parseInt(a[index]) && parseInt(a[index])!==parseInt(fila[index].offsetWidth-7) && b.campos[index]!==undefined){
              b.$emit('evento',{
                campo: b.campos[index].nombre,
                ancho: fila[index].offsetWidth-7,
                fijado: b.campos[index].fijado
              })
              // Aqui emito un evento para que el elemento padre se comunique con el backend y cambie las preferencias
            }
            array.push(parseInt(fila[index].offsetWidth-7))
          }
          a = array
      }, 1000); // esta funcion se ejecutara cada segundo
    },
    beforeUnmount(){
      clearInterval(this.algo)
    }
}
</script>

<style scoped>
.link{
  cursor: pointer;
}
.link:hover{
  text-decoration: underline;
}
.container-encabezados{
  user-select: none;
  flex-grow: 0;
  overflow-x: scroll;
}
.container-encabezados::-webkit-scrollbar {
  display: none;
}
.container{
  flex-grow: 1;
  overflow: scroll;
  height: 80vh;
}
table{
  text-align: left;
  margin: 0;
  border-collapse: collapse;
  table-layout: fixed;
  border: none;
  padding: 0;
}
th input[type='text']{
  width: calc(100% - 6px);
  margin: 0;
}
th a{
  width: max-content;
}
th div{
  display: flex;
}
.titulos:hover{
  background: white;
}
.registro{
  font-size: 90%;
  height: min(5vh,40px);
  text-align: left;
}
.registro .sticky{
  background-color: white;
  position: sticky;
}
.titulos .sticky{
  position: sticky;
  z-index: 10;
  background-color: rgb(255, 172, 116);
}
thead th{
  position: sticky;
  top: 0;
  table-layout: fixed;
  z-index: 1;
  background-color: rgb(255, 172, 116);
  color: white;
  font-weight: normal;
  padding: 0 3px 2px 3px;
  text-align: left;
  box-shadow: inset 0 -1px 0 black;
}
.registro td{
  height: max(5vh,40px);
  z-index: 2;
  table-layout: fixed;
  max-lines: 2;
  padding: 0 3px;
  box-shadow: inset 0 -1px 1px black, inset -1px 0 0 black;
  overflow: hidden
}
.registro td span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-lines: 2;
  word-break: normal;
  white-space: wrap;
}
.registro td input{
  border: none;
  background: transparent;
  width: calc(100% - 6px);
}
.registro td input:focus{
  outline: none;
}
.registro td select{
  width: calc(100% - 6px);
  border: none;
  background: transparent;
}
.registro td select:focus{
  outline: none;
}
.registro span{
  word-break: break-all;
  width: 100%;
}
.registro a:hover{
  text-decoration: underline;
}
td a{
  cursor: pointer;
}
tr:hover{
  background-color: rgb(230, 230, 230);
}
.registro:hover .sticky{
  background-color: rgb(230, 230, 230);
}
th label{
  cursor: pointer;
}
</style>