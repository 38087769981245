<template>
    <div class="filtro-master" v-if="campo && tipo">
        <div class="filtro-second" v-if="tipo==='string' || tipo==='option-long'">
            <input @change="cambios" type="text" v-model="valores[0]">
        </div>
        <div class="filtro-second bool" v-else-if="tipo==='boolean'">
            Sí<input @change="cambios" type="checkbox" v-model="valores[0]">
            No<input @change="cambios" type="checkbox" v-model="valores[1]">
        </div>
        <div class="filtro-second number" v-else-if="tipo==='number' || tipo==='money'">
            <input @change="cambios" type="number" v-model="valores[0]" placeholder="Min">
            <input @change="cambios" type="number" v-model="valores[1]" placeholder="Máx">
        </div>
        <div class="filtro-second" v-else-if="tipo==='option'">
            <Desplegable :datos="opciones" @yep="e=>desplegables(e)"/>
        </div>
        <div class="filtro-second" v-else-if="tipo==='date'">
            <!-- Aqui toca el desplegable de Fechas -->
        </div>
        <input v-if="tipo==='number' || tipo==='money'" type="checkbox" v-model="nulo" @change="valores[0]=null;valores[1]=null">
        <input v-if="tipo==='string' || tipo==='option-long'" type="checkbox" v-model="nulo" @change="valores[0]=null">
        <input v-if="tipo==='option'" type="checkbox" v-model="nulo" @change="valores=[]">
    </div>
</template>

<script>
import Desplegable from './Desplegable.vue'
export default {
    name: 'Filtro',
    data: () => {
        return {
            valores: [],
            nulo: false
        }
    },
    computed: {
        query(){
            // Aqui vendra el traspaso de datos hacia una SQL query (desde WHERE hacia adelante)
            let respuesta = null
            if(this.nulo===false){
                if(this.tipo==='string'){
                    if(this.valores[0]!==null && this.valores[0]!==''){
                        respuesta = (" LIKE '%").concat(this.valores[0],"%'")
                    }
                }else if(this.tipo==='option'){
                    if(this.valores.length>0){
                        respuesta = ' IN ('+this.valores.join(',')+')'
                    }
                }else if(this.tipo==='option-long'){
                    if(this.valores[0]!==null && this.valores[0]!==''){
                        respuesta = " LIKE '%"+this.valores[0]+"%'"
                    }
                }else if(this.tipo === 'number' || this.tipo === 'money'){
                    let a = (parseInt(this.valores[0])===parseInt(Number(this.valores[0])))
                    let b = (parseInt(this.valores[1])===parseInt(Number(this.valores[1])))
                    if (a && b) {
                        respuesta = ' BETWEEN '+this.valores[0]+' AND '+this.valores[1]
                    }else if(a){
                        respuesta = '>='+this.valores[0]
                    }else if(b){
                        respuesta = '<='+this.valores[1]
                    }
                }else if(this.tipo==='boolean'){
                    let array = []
                    if(this.valores[0]===true){
                        array.push(1)
                    }
                    if(this.valores[1]===true){
                        array.push(0)
                    }
                    if(array.length===0){
                        respuesta = ' IS NULL'
                    }else{
                        respuesta = ' IN ('+array.join(',')+')'
                    }
                }
                if(this.tipo==='option-long'){
                    return ['Z'+this.campo,respuesta]
                }else{
                    return [this.campo,respuesta]
                }
            }else{
                return this.campo+' IS NULL'
            }
        }
    },
    components:{
        Desplegable
    },
    methods:{
        cambios(){
            this.$emit('filtrar',this.query)
        },
        desplegables(e){
            this.valores = e.map(item => {
                return item.code
            })
            this.cambios()
        },
        cargar(){
            if(this.tipo === 'string' || this.tipo === 'option-long'){
                this.valores[0] = null
            }
            else if(this.multiple===false && this.tipo==='option'){
                this.valores[0] = null
            }
            else if(this.tipo === 'number' || this.tipo === 'money'){
                this.valores[0] = null
                this.valores[1] = null
            }
            else if(this.tipo==='boolean'){
                this.valores[0] = true
                this.valores[1] = true
            }
        }
    },
    props: {
        campo: {
            type: String,
            required: true
        },
        tipo: {
            type: String,
            required: true
        },
        multiple: {
            type: Boolean,
            default: false,
            required: false
        },
        value: {
            type: String,
            default: null,
            required: false
        },
        opciones: {
            type: Array,
            required: false
        }
    },
    beforeMount(){
        this.cargar()
    }
}
</script>

<style scoped>
.filtro-master{
    width: 100%;
    display: flex;
}
.filtro-second{
    width: 100%;
}
.filtro-second .bool,.number{
    display: flex;
}
.filtro-second input[type=text]{
    width: 100%
}
.filtro-second input[type=number]{
    width: calc(calc(100% - 13px)/2);
}
input[type="checkbox"]{
    flex-grow: 0;
}
</style>