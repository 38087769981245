<template>
    <div name="nada" class="select-container">
        <input type="checkbox" id="desplegad" v-model="desplegado" style="display:none">
        <div class="main">
            <!-- Cuadro de busqueda -->
            <input v-if="multiple===true && desplegado===true" name="nada" class="despl" @input="filtrar" @focus="desplegado=true" type="text" v-model="busqueda" :placeholder="value.length+' valor/es'">
            <input v-if="multiple===true && desplegado===false" name="nada" @input="filtrar" @focus="desplegado=true" type="text" v-model="busqueda" :placeholder="value.length+' valor/es'">
            <input v-else-if="multiple===false && desplegado===true" name="nada" class="despl" @input="filtrar" @focus="desplegado=true" type="text" v-model="busqueda" :placeholder="value.label">
            <input v-else-if="multiple===false && desplegado===false" name="nada" @input="filtrar" @focus="desplegado=true" type="text" v-model="busqueda" :placeholder="value.label">
            <!-- Boton para eliminar el valor actual -->
            <button class="cancelar" v-if="!desplegado" @click.prevent="value=[];cambio()">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                    <path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path>
                </svg>
            </button>
            <!-- Boton para desplegar la lista -->
            <input id="desplegado" type="checkbox" style="display:none" >
            <label for="desplegado" class="desplegar" @click="desplegado=!desplegado">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10"><path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path></svg>
            </label>
        </div>
        <!-- Lista de items del desplegable -->
        <ul class="select-list" v-if="desplegado">
            <li @click="cambio(item)" :value="item.code" v-for="(item,index) in filtrado" :key="index">
                {{item.label}}
            </li>
        </ul>
    </div> 
</template>

<script>
import { mapState } from 'vuex' 
export default {
    name: 'Desplegable',
    data: function () {
        return {
            busqueda: null,
            desplegado: false,
            original: this.datos,
            filtrado: this.datos,
            value: []
        }
    },
    props:{
        datos: Array,
        multiple: {
            type: Boolean,
            default: true
        },
        '--ancho': {
            type: String,
            default: '100px'
        }
    },
    methods: {
        cambio(value){
            if(value && this.multiple){
                this.value.push(value)
            }else if(value && this.multiple===false){
                this.value = value
            }
            this.busqueda = null
            this.filtrado = this.original
            this.desplegado = false
            this.$emit('yep',this.value)
        },
        filtrar(){
            let filtro = this.busqueda
            if(filtro!==null){
                this.filtrado = this.original.filter(function (e){
                    return e['label'].toUpperCase().includes(filtro.toUpperCase())
                })
            }else{
                this.filtrado = this.original
            }
        },
        nuevoitem(){

        },
    }
}
</script>

<style scoped>
.select-container{
    position: relative;
    font-family: inherit;
    width: max-content;
    font-size: 18px;
    text-align: left;
    width: 100%;
}
.main{
    border: solid 1px rgb(150,150,150);
    border-radius: 5px;
    background: white;
}
.select-list{
    display: block;
    top: 30;
    left: 0;
    right: 0;
    width: calc(100%-2px);
    appearance: none;
    list-style: none;
    position: absolute;
    background: white;
    margin: 0;
    padding: 0;
    border: 1px solid rgb(150,150,150);
    border-top: none;
    border-radius: 0 0 5px 5px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}
.select-list li{
    width: calc(100%-4px);
    padding: 2px;
    margin: 0;
    position: relative;
    cursor: pointer;
    color: black;
    z-index: 2;
    background: white;
}
.select-list li:hover{
    color: white;
    background: rgb(88,151,251);
}
input::placeholder{
    color: inherit;
}
input{
    width: calc(100% - 50px);
    padding: 3px;
    background: transparent;
    border: none;
    font-family: inherit;
    font-size: inherit;
}
.despl{
    width: calc(100% - 25px);
}
input:focus{
    outline: none;
}
input:focus::placeholder{
    color: grey;
}
button{
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
}
label{
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
}
.cancelar{
    fill: rgba(60,60,60,0.5);
}
.desplegar{
    fill: rgba(60,60,60,0.5);
    transition: 0.4s all;
}
#desplegado:checked + .desplegar{
    transform: rotate(180deg);
}
#desplegad:checked + .main{
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid transparent;
}
</style>